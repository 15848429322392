import Link from 'next/link';
import { useState } from 'react';
import Image from 'next/image';
import Head from 'next/head';
import { useForm } from 'react-hook-form';
import { useAuth } from '@/lib/auth';
import { useRouter } from 'next/router';
import { AuthProps } from 'types/AuthProps';

const Login = () => {
    const [createWithEmail, setCreateWithEmail] = useState<string>();
    const { register, handleSubmit } = useForm();
    console.log('loaded login page');

    const auth = useAuth();
    const router = useRouter();

    if (auth?.user?.isVerified) {
        router.push('/');
    }
    const onSubmit = (data: any) => {
        setCreateWithEmail(data.email);
        auth.sendSignInLink(data.email);
        // console.log(auth);
    };

    return (
        <>
            <Head>
                <title>Log into Virra</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="flex min-h-screen justify-center bg-white">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <a href="https://www.virra.com/">
                                <Image
                                    className="inline-block h-12 w-auto rounded-lg hover:bg-gray-100"
                                    src="/assets/icons/virra-logo-min.svg"
                                    alt=""
                                    width={48}
                                    height={48}
                                />
                            </a>
                            {createWithEmail ? (
                                <h1 className="mt-6 text-3xl font-extrabold text-gray-800">
                                    An email is on its way!
                                </h1>
                            ) : (
                                <h1 className="mt-6 text-3xl font-extrabold text-gray-800">
                                    Log in or Sign Up
                                </h1>
                            )}
                        </div>

                        <div className="mt-8">
                            {createWithEmail ? (
                                <div className="prose">
                                    <p className="m-0 p-0">
                                        We just sent you an email with a link to
                                        log in. It might take a few minutes for
                                        it to arrive.
                                    </p>
                                    <p className="font-bold">
                                        See you on the inside!
                                    </p>

                                    {/* <div>
                                        <h2>Here’s what to expect</h2>
                                        <ul></ul>
                                    </div> */}
                                </div>
                            ) : (
                                <div className="relative mt-6">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="space-y-6"
                                    >
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-lg font-medium text-gray-700"
                                            >
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    placeholder="tony@starkindustries.com"
                                                    required
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                                                    {...register('email')}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                            >
                                                Get Magic Link
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
